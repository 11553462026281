<template>
  <div class="tickets-cart" data-dropdown v-if="showCart">
    <div class="card-counter">
      {{ ticketsCount }}
    </div>
    <img
      src="./../../assets/tickets-cart.svg"
      class="cart-icon"
      alt="cart"
      data-dropdown-button
    />
    <div class="dropdown-menu">
      <div class="dropdown-arrow"></div>
      <div class="cart-dropdown">
        <div class="cart-header">
          <span>{{ $t("ticket_cart") }}</span>
          <span class="material-icons" @click="closeMenu"> close </span>
        </div>
        <div class="cart-items">
          <div
            class="cart-item"
            v-for="(ticket, index) in storedTickets"
            :key="index"
          >
            <div class="ticket-title">
              {{ ticket.event_name }}
            </div>
            <div class="ticket-details">
              <div class="ticket-type">
                <span v-if="ticket.event_category"
                  >{{ ticket.event_category }} •
                </span>
                {{ ticket.sp_ticket_type_name }}
              </div>
              <div class="cart-actions">
                <img
                  src="./../../assets/trash_can.svg"
                  alt="remove"
                  @click.stop="updateTicketsListItem({ ticket, quantity: 0 })"
                />
                <span>{{ ticket.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="useAccountCheckout"
          class="cart-footer"
          @click="goToCheckout"
        >
          <span>{{ $t("commit_now") }}</span>
        </div>
        <a
          v-if="!useAccountCheckout"
          :href="tsCheckoutLink"
          target="_blank"
          class="cart-footer-link"
          @click="clearCart"
        >
          <span>{{ $t("commit_now") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import commitLinkResolver from "./commitLinkResolver";
export default {
  name: "TicketsCartDropdown",
  data: () => ({}),
  mixins: [commitLinkResolver],
  computed: {
    ...mapGetters(["storedTickets"]),
    showCart() {
      return this.storedTickets.length > 0;
    },
  },
  methods: {
    ...mapActions(["updateTicketsListItem"]),
    closeMenu() {
      document
        .querySelectorAll("[data-dropdown].active")
        .forEach((dropdown) => {
          dropdown.classList.remove("active");
        });
    },
    toggleCartDropdown(e) {
      const isDropdownButton = e.target.matches("[data-dropdown-button]");
      if (!isDropdownButton && e.target.closest("[data-dropdown]") != null)
        return;

      let currentDropdown;
      if (isDropdownButton) {
        currentDropdown = e.target.closest("[data-dropdown]");

        currentDropdown.classList.toggle("active");
      }
      document
        .querySelectorAll("[data-dropdown].active")
        .forEach((dropdown) => {
          if (dropdown === currentDropdown) return;
          dropdown.classList.remove("active");
        });
    },
  },
  created() {
    document.addEventListener("click", this.toggleCartDropdown);
  },
  destroyed() {
    document.removeEventListener("click", this.toggleCartDropdown);
  },
};
</script>
<style lang="scss" scoped>
.tickets-cart {
  display: flex;
  position: relative;
  margin: 0 30px;
  color: #000000;
  @media screen and (max-width: 1025px) {
    display: none;
  }
  .card-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -100%);
    font-size: 11.5px;
    font-weight: bold;
    color: #ffffff;
    background-color: #cc092f;
    border: 3px solid #000000;
    border-radius: 14px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
  .cart-icon {
    @include cursorPointer;
  }
  .dropdown-menu {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    left: 14px;
  }
  .dropdown-arrow {
    position: absolute;
    top: 26px;
    height: 17px;
    width: 17px;
    background-color: #ffffff;
    transform: translate(-50%, 100%) rotate(45deg);
    @media screen and (max-width: 1280px) {
      top: 18px;
    }
  }
  .cart-dropdown {
    position: absolute;
    top: 51px;
    transform: translate(-50%, 0);
    height: 385px;
    width: 342px;
    background-color: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    @media screen and (max-width: 1279px) {
      top: 43px;
    }
    .cart-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 67px;
      padding-left: 24px;
      padding-right: 20px;
      border-bottom: 1px solid #e0e0e0;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.0025em;
      .material-icons {
        @include cursorPointer;
      }
    }
    .cart-items {
      height: 258px;
      overflow-y: auto;
      .cart-item {
        padding: 17px 22px 19px 22px;
        .ticket-title {
          font-size: 10px;
          line-height: 12px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #bbbbbb;
          max-width: 240px;
          margin-bottom: 8px;
        }
        .ticket-details {
          display: grid;
          grid-template-columns: 240px 1fr;
          column-gap: 8px;
          align-items: center;

          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          letter-spacing: 0.0025em;
          .ticket-type {
          }
          .cart-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #4f4f4f;
            img {
              @include cursorPointer;
            }
          }
        }
      }
      .cart-item:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
    .cart-footer,
    .cart-footer-link {
      background-color: #cf1019;
      height: 60px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cart-footer {
      @include cursorPointer;
    }
    .cart-footer-link {
      text-decoration: none;
    }
  }
}
.tickets-cart.active > img + .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
</style>